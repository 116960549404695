.cutPrice {
    width: 20.8125rem;
    height: 18.625rem;
    background: #FFFCF7;
    border-radius: .4375rem;
    padding-top: 1.4375rem;
    margin: 0 auto;
    margin-bottom: 5.75rem;

    .cut-price {
        width: 100%;
        text-align: center;
        height: 3.3125rem;
        font-size: 3.25rem;
        font-family: Arial Rounded MT Bold;
        font-weight: 400;
        line-height: 3rem;
    }

    .cut-progress {
        padding: 1.25rem 1.625rem 0 1.625rem;
        width: 100%;
    }

    .cut-maxprice {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        height: .8125rem;
        font-size: .6875rem;
        font-weight: 400;
        padding-right: 1.625rem;
    }

    .cut-buttom {
        width: 100%;
        height: 3.5rem;
        margin-top: 1.25rem;
        margin-bottom: 1.5625rem;
        padding: 0 2.75rem;
    }

    .cut-btn {
        width: 100%;
        height: 100%;
        background: #FFEB69;
        border-radius: 1.625rem;
        display: flex;
    }

    .cut-icon {
        width: 1.375rem;
        height: 2.1875rem;
        margin: .625rem .5313rem .6875rem 3.125rem;
    }

    .cut-icon img {
        width: 1.3125rem;
        height: 2.1875rem;
    }

    .cut-text {
        width: 9.375rem;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 3.5rem;
        color: #000;
    }

    .cut-time {
        display: flex;
        width: 100%;
        height: 1.3125rem;
        padding: 0 4.6875rem;
    }

    .cut-time-text {
        font-weight: 400;
        line-height: 1.3125rem;
        margin-right: .3125rem;
        width: 5.1875rem;
        height: .6875rem;
        font-size: .8125rem;
    }

    .cut-time-time {
        display: flex;
    }

    .cut-time-time span {
        display: block;
        width: 1.3125rem;
        height: 1.3125rem;
        background: #D6BA85;
        border-radius: .0938rem;
        font-size: .6875rem;
        font-weight: 400;
        color: #fff;
        text-align: center;
        line-height: 1.3125rem;
        margin: 0 .1875rem;
    }

    /* 砍价成功 */
    .cut-btn-success {
        background: linear-gradient(86deg, #FF23BE, #FF7011);
        border-radius: 1.625rem;
        width: 100%;
        height: 100%;
        display: flex;
    }

    .cut-icon-success {
        width: 1.3125rem;
        height: 1.125rem;
        margin: .875rem .875rem 1.2813rem 3.75rem;
    }

    .cut-icon-success img {
        width: 1.375rem;
        height: 1.125rem;
    }

    .cut-time-success {
        width: 100%;
        height: .875rem;
        font-size: .9375rem;
        font-weight: 400;
        line-height: 3.25rem;
        text-align: center;
    }

    /* 砍价失败 */
    .cut-btn-fail {
        width: 100%;
        height: 100%;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.3125rem;
        text-align: center;
    }

    .cut-time-fail {
        display: flex;
        width: 100%;
        height: 1.3125rem;
        padding-left: 4.1875rem;
    }

    .cut-time-text-fail {
        width: 1.375rem;
        height: 1.1875rem;
        margin-right: .6875rem;
    }

    .cut-time-text-fail img {
        width: 1.375rem;
        height: 1.1875rem;
    }

    .cut-time-time-fail {
        width: 100%;
        height: .875rem;
        font-size: .9375rem;
        font-weight: 400;
    }

    .cut-time-time-fail img {
        width: .5625rem;
        height: .6875rem;
        margin-left: .4688rem;
        font-weight: 900;
    }

    /* 好友砍价成功 */
    .cut-btnSuccess {
        width: 100%;
        height: 100%;
        background: #F5E6CA;
        border-radius: 1.625rem;
        display: flex;
    }

    .cut-iconSuccess {
        width: 1.125rem;
        height: .75rem;
        margin: 1.375rem .4688rem 1.3438rem 3.4063rem;
    }

    .cut-iconSuccess img {
        width: 1.125rem;
        height: .75rem;
    }

    .cut-textSuccess {
        width: 6.5rem;
        height: .8125rem;
        font-size: 1.125rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #C4A873;
        line-height: 3.5rem;
    }

}