.index-main{
    width: 100%;
    // height: 100%;

    .index-search{
        width: 240px;
        height: 32px;
        margin-bottom: 20px;
    }

    .index-table{

        .ant-table-tbody>tr:nth-child(odd)>td {
            background-color: #FFF;
        }

        .ant-table-tbody>tr:nth-child(even)>td {
            background-color: #F2F2F2;
        }
    }
}
.tableImg{
    width: 54px;
    height: 46px;
    margin-right: 20px;
}