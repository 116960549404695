.mask {
    width: 100%;
    height: 106.25rem;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    z-index: 100001;

    .content {
        position: fixed;
        height: 22.3438rem;
        width: 20.8438rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #FFFCF7;
        border-radius: .4375rem;
        z-index: 100002;
    }

    .close {
        width: 100%;
        height: .8438rem;
        margin-top: .75rem;
        margin-bottom: .875rem;
        display: flex;
        flex-direction: row-reverse;
    }

    .delspan {
        display: block;
        width: .8438rem;
        height: .8438rem;
        margin-right: .8125rem;
    }

    .delimg {
        width: .8438rem;
        height: .8438rem;
    }

    .login-title {
        text-align: center;
        width: 100%;
        height: 1.0625rem;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.0625rem;
        margin-bottom: 1.5625rem;
    }

    .loginForm {
        height: 15rem;
        width: 100%;
        padding: 0 2.5rem;
    }

    .login-top {
        width: 100%;
        height: 3.4688rem;
        border: 0;
        outline: none;
        background: #F7F2E8;
        display: flex;
        margin-bottom: .8438rem;
    }

    .login-top .select-ul {
        list-style: none;
        position: absolute;
        top: 8.4375rem;
    }

    .phone-header {
        width: 2.8125rem;
        height: 3.4688rem;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 3.4688rem;
        // padding-left: .625rem;
    }

    .loginPhone {
        width: 100%;
        height: 3.4688rem;
        border: 0;
        outline: none;
        background: #F7F2E8;
        border-radius: .0938rem;
        padding: 1.3125rem 0 1.3125rem 1.3125rem;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 3.4688rem;
    }

    .ant-input-group {
        width: 100%;
        height: 3.4688rem;
    }

    .Verification {
        display: flex;
        margin-bottom: 1.0313rem;
    }

    .loginVerification {
        width: 10.1875rem;
        height: 3.4688rem;
        border: 0;
        outline: none;
        background: #F7F2E8;
        border-radius: .0938rem;
        padding: 1.3125rem 0 1.3125rem 1.3125rem;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 3.4688rem;
        margin-right: .5313rem;
    }

    .loginSend {
        display: block;
        width: 5.1563rem;
        height: 3.4688rem;
        background: #FFEB69;
        border-radius: .0938rem;
        font-size: .9063rem;
        font-weight: 500;
        line-height: 3.4688rem;
        text-align: center;
    }

    .loginResend{
        display: block;
        width: 5.1563rem;
        height: 3.4688rem;
        background: #F7F2E8;
        border-radius: .0938rem;
        font-size: .625rem;
        font-weight: 500;
        line-height: 3.4688rem;
        text-align: center;
    }

    .loginTips {
        width: 100%;
        height: 1.5rem;
        padding: 0 1.4063rem;
        font-size: .6875rem;
        font-weight: 500;
        color: #D8C9AA;
        text-align: center;
        margin-bottom: 1.1875rem;
    }

    .loginBtn {
        width: 100%;
        height: 3.4688rem;
        background: #F7F2E8;
        border-radius: 1.625rem;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 3.4688rem;
        text-align: center;
    }

    .loginBtn2 {
        width: 100%;
        height: 3.4688rem;
        background: #FFEB69;
        border-radius: 1.625rem;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 3.4688rem;
        text-align: center;
    }

    .ant-select {
        font-size: 1.125rem;
        font-weight: 500;
        color: #000000;
    }

    .ant-select-selector {
        border: none !important;
        height: 1.4063rem !important;
        background-color: #F7F2E8 !important;
    }

    .ant-select-selection__rendered {
        height: 100%;
    }

    .ant-select-selection {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }

    .ant-select-item {
        height: 1.4688rem !important;
        font-size: 1.125rem !important;
        line-height: 1.4688rem !important;
    }

}
.select-login {
    z-index: 100003 !important;
    position: fixed !important;
    top: 17.1875rem !important;
    left: 4.0625rem !important;
    height: 14rem;
}
.ant-select-selection-item{
    padding-right: 0 !important;
}