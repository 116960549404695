.create-topbar {
    height: 40px;
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;

    .create-left {
        display: flex;

        .create-clickbar {
            width: 72px;
            height: 100%;
            font-size: 18px;
            margin: 0 16px 0px 0;
            color: #BBB;
        }

        span {
            font-size: 18px;
            text-align: left;
        }
        .topname{
            color: #C86032;
        }

        .deitItem {
            color: #BBB;
        }
    }

    .create-btn {
        color: rgba(255, 255, 255, 100);
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        width: 185px;
        height: 40px;
        background: #C86032;
        border-radius: 20px;
        cursor: pointer;
    }
}

.create-main {
    width: 100%;
    height: 100%;

    .table-bar {
        display: flex;

        span {
            display: block;
            margin-right: 53px;
            margin-bottom: 27px;
            height: 26px;
            left: 181px;
            font-size: 18px;
            line-height: 30px;
            color:#BBB
        }

        .point {
            width: 120px;
            height: 30px;
            background: #C86032;
            border-radius: 15px;
            text-align: center;
            display: block;
            margin-right: 53px;
            font-size: 18px;
            color: #FFF !important;
        }
    
        .nopoint {
            width: 120px;
            height: 30px;
            color: #635652;
            border-radius: 15px;
            text-align: center;
            display: block;
            margin-right: 53px;
            font-size: 18px; 
        }
    
        .nopoint:hover{
            color: #C86032 !important;
            cursor: pointer;
        }
    }

    .search-input{
        width: 408px;
        height: 40px;
        border-radius: 20px;
        position: relative;
    
        img{
            position: absolute;
            width: 27px;
            height: 27px;
            left: 8px;
            margin-top: 6px;
            margin-left: 6px;
            z-index: 999;
        }
    
        Input{
            width: 408px;
            height: 40px;
            background: #F9F6F5;
            border-radius: 20px;
            text-indent: 40px;
            border: none;
        }
        .input-main:hover{
            border: none;
        }
    }
}

img{
    width: 60px;
    height: 60px;
}