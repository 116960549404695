.create-topbar2 {
    height: 40px;
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;

    .create-left {
        display: flex;

        .create-clickbar {
            width: 72px;
            height: 100%;
            font-size: 18px;
            margin: 0 16px 0px 0;
            color: #BBB;
        }

        span {
            font-size: 18px;
            text-align: left;
        }

        span:nth-child(2) {
            color: #BBB;
        }

        span:nth-child(3) {
            color: #C86032;
        }
    }

    .btn {
        display: flex;

        .create-btn-first {
            color: rgba(255, 255, 255, 100);
            font-size: 14px;
            text-align: center;
            line-height: 40px;
            border-radius: 20px;
            margin-right: 20px;
            cursor: pointer;
            width: 185px;
            height: 40px;
            background: #D0B5AA;
            border-radius: 20px;
        }

        .create-btn {
            color: rgba(255, 255, 255, 100);
            font-size: 14px;
            text-align: center;
            line-height: 40px;
            width: 185px;
            height: 40px;
            background: #C86032;
            border-radius: 20px;
            margin-right: 20px;
            cursor: pointer;
        }
    }
}

.already-selected {
    width: 100%;
    height: 86px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #635652 !important;
    line-height: 17px;
    margin-bottom: 40px;

    .products-name {
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        margin-top: 28px;
    }
}

.edit-radio {
    text-align: left;
    margin-bottom: 50px;
    height: 17px;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;

    span {
        color: #635652 !important;
    }
}

.edit-main {
    width: 100%;
    height: 100%;

    // input输入框
    .ant-input {
        width: 106px;
        height: 26px;
        background: #F3EEEC;
        border-radius: 15px;
        border: 0;
        text-align: center;
        margin: 0 13px 0 6px
    }

    .edit-main-input {
        display: flex;
        height: 60px;
        font-size: 18px;
        line-height: 26px;
        // margin-bottom: 50px;

        .before-input {
            padding-right: 30px;
            height: 26px;
            font-size: 16px;
            font-weight: 500;
            color: #635652;
            line-height: 26px;
            margin: 0;
        }

        .max-message {
            font-size: 13px;
            font-weight: 400;
            color: #C86032;
            line-height: 26px;
        }

        .discount {
            font-size: 13px;

            .discount-top {
                line-height: 26px;
                height: 26px;

                span {
                    display: inline-block;
                    margin: 0 12px 0 12px;
                }

                .input-time {
                    margin: 0 !important;
                    margin-right: 13px !important;
                    border-radius: 5px !important;
                }

                .discount-span {
                    margin-right: 27px;
                    color: rgba(93, 93, 93, 100);
                    font-size: 14px;
                    line-height: 26px;
                }

                // input输入框
                .ant-input {
                    width: 106px;
                    height: 26px;
                    background: #F3EEEC;
                    border-radius: 15px;
                    border: 0;
                    text-align: center;
                    margin: 0 13px 0 6px
                }
            }

        }
    }

    .edit-title {
        display: flex;
        margin-bottom: 35px;
        padding-right: 30px;
        height: 26px;
        font-size: 16px;
        font-weight: 500;
        color: #635652;
        line-height: 26px;

        .input-time {
            margin: 0 !important;
            margin-right: 13px !important;
            border-radius: 5px !important;
        }

        span {
            display: inline-block;
            margin: 0 12px 0 12px;
        }
    }

    .shop-table {
        width: 100%;
        height: 194px;
        margin-bottom: 16px;
        display: flex;

        img {
            width: 195px;
            height: 194px;
            border: none;
            margin-right: 29px;
        }

        .shop-message {
            width: 600px;
            height: 194px;
            font-size: 16px;
            font-weight: 500;
            color: #635652;
            line-height: 26px;
        }

        .shop-edit {
            width: 100%;
            height: 120px;
            font-size: 16px;
            font-weight: 500;
            color: #635652;
            line-height: 26px;

            .cut-max {
                display: flex;
                width: 100%;
                height: 75px;
                margin-bottom: 15px;

                p {
                    width: 140px;
                    height: 26px;
                }

                .ant-radio-wrapper {
                    font-size: 14px !important;
                    color: #635652 !important;
                }
            }

            .cut-section {
                display: flex;
                width: 100%;
                height: 30px;

                p {
                    width: 166px;
                    height: 26px;
                }
            }
        }
    }
}

.ant-radio-checked .ant-radio-inner {
    border-color: #C86032;
}

.ant-radio-inner::after {
    background-color: #C86032;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: #C86032;
}

.ant-radio-checked::after {
    border: 1px solid #C86032;
}

.message {
    color: red;
    font-size: 12px;
}

// 日期输入框
.ant-picker {
    border-radius: 15px;
    border: 0;
    height: 26px;
    background: #F3EEEC;
    border-radius: 15px;
    border: 0;
    text-align: center;
}