.ant-layout {
  display: flex;
  width: 100%;
  min-height: 100% !important;
}

// .ant-layout-content {
//   padding: 0 !important;
// }

.site-layout-background {
  background: #FFF !important;
}

.ant-layout-sider-children{
  border-right: 1px solid #BBB;
}

.ant-layout-header{
  display: flex;
  height: 100px !important;
  width: 100%;
  background-color: #C86032 !important;
  padding: 0 !important;
  justify-content: space-between;
  border: 0 solid #BBB;

  .header-left{
    display: flex;

    .logo{
      width: 96px;
      height: 65px;
      margin: 19px 21px 16px 37px;
    }
  
    .website-name{
      width: 56px;
      height: 100px;
      line-height: 100px;
      text-align: left;
    }
  }

  .header-right{
    display: flex;

    .upgrade{
      margin: 35px 25px 35px 0;
      width: 80px;
      height: 30px;
      border-radius: 4px;
      text-align: center;
      font-family: Roboto;
      line-height: 30px;
      border: 1px solid rgba(187, 187, 187, 100);
    }

    .support{
      margin-right: 45px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }

    .head-portrait{
      margin: 30px 10px 30px 0;
      border-radius: 40px;
      text-align: center;
      border: 1px solid rgba(187, 187, 187, 100);
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    .username{
      margin-right: 26px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }
  
}
