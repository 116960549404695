.index{
    width: 100%;
    height: 100%;
}

.appearanceMmessage{
    height: 50px;
    width: 100%;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    background-color: #0edcc1;
    position: relative;
    top: -24px;
    // left: -24px;

    span{
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }

    .anticon-close{
        color: #FFF;
        
    }
}

.index-topbar{
    height: 26px;
    width: 520px;
    display: flex;
    margin-bottom: 31px;

    .clickbar{
        width: 180px;
        height: 40px;
        background: #C86032;
        color: #FFF;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        margin: 0 80px 0px 0;
        cursor: pointer;
    }

    .noclickbar{
        width: 180px;
        height: 40px;
        font-size: 18px;
        margin: 0 80px 0px 0;
        color: #C86032 ;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }
}
