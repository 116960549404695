.Terms{
    background-color: #fff;
}
.Terms-main{
    padding-top: 40px;
    padding: 40px 351px 0 348px;
    width: 100%;

    .title{
        height: 20px;
        font-size: 23px;
        font-weight: 500;
        color: #000;
        margin-bottom: 23px;
    }

    .Terms-text{

        p{
            color: #000;
            font-size: 16px;
        }
    }
}