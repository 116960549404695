.main{
  width: 100%;
  height: 100%;
}

.form-main{
  width: 378px;
  height: 900px;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  .form-img{
    width: 112px;
    height: 112px;
    margin: 166px 133px 54px 133px;
    img{
      width: 112px;
      height: 112px;
    }
  }

  .title{
    width: 100%;
    font-size: 29px;
    font-family: HYYuanLongHei;
    font-weight: normal;
    color: #C86032;
    line-height: 30px;
    text-align: center;
    margin-bottom: 64px;
  }

  .titlelast{
    font-size: 18px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #CCBDB9;
    line-height: 17px;
    img{
      width: 16px;
      height: 18px;
    }
  }

  .footer{
    width: 100%;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #CCBDB9;
    line-height: 17px;
    text-align: center;
    position: absolute;
    bottom: 30px;;
    cursor: pointer;
  }

  .ant-input{
    width: 100%;
    height: 40px;
    border: 1px solid #C1A395;
    border-radius: 3px;
  }
}

.login-form-button{
  width: 100%;
  height: 45px;
  background: #C86032;
  border-radius: 23px;
  border: 0;

  font-size: 17px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 37px;
}

.login-form-button:hover{
  width: 100%;
  height: 45px;
  background: #C86032;
  border-radius: 23px;
  border: 0;
}

.ant-btn-primary:focus {
  color: #fff;
  border-color: #C86032;
  background: #C86032;
}