.hot-topbar {
    height: 40px;
    width: 100%;
    display: flex;
    margin-bottom: 50px;

    .hot-clickbar {
        width: 170px;
        height: 100%;
        font-size: 18px;
        margin: 0 16px 0 0;
        color: #BBB;
    }

    span {
        font-size: 18px;
        text-align: left;
    }
}

.hot-header {
    width: 100%;
    // height: 221px;
    margin-bottom: 60px;

    .create-time {
        height: 26px;
        width: 100%;
        line-height: 26px;
        margin-bottom: 26px;
        font-size: 16px;
    }

    .messages {
        width: 100%;
        font-size: 16px;
        line-height: 26px;

        .msg-message {
            margin-bottom: 26px;
        }

        .msg-time {
            margin-bottom: 26px;
        }

        .msg-times {
            margin-bottom: 26px;
        }

        .active-state {
            margin: 0;
            display: flex;

            .active-nostart{
                display: flex;
            }
        }
    }
}

.active-btn {
    width: 144px;
    height: 30px;
    margin-left: 20px;
    line-height: 30px;
    color: #fff;
    font-family: PingFang SC;
    text-align: center;
    background: #C86032;
    cursor: pointer;
    border-radius: 20px;
}

.hot-main {
    height: 262px;
    width: 100%;
    margin-bottom: 54px;

    .active-data {
        width: 100%;
        height: 26px;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 26px;
    }

    .main {
        height: 40px;
        width: 100%;
        margin-bottom: 45px;
        display: flex;
        justify-content: space-between;

        div {
            width: 592px;
            height: 40px;

            span:nth-child(1) {
                display: inline-block;
                min-width: 100px;
                height: 35px;
                color: rgba(16, 16, 16, 100);
                font-size: 18px;
                text-align: left;
            }

            span:nth-child(2) {
                display: inline-block;
                height: 26px;
                font-size: 16px;
                line-height: 40px;
                text-align: left;
            }
        }
    }
}

.activity-goods {
    height: 505px;
    width: 100%;

    .top {
        width: 100%;
        height: 26px;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 18px;
    }

    .goods {
        width: 100%;
        height: 87px;
        font-size: 16px;
        margin-bottom: 13px;
        display: flex;

        .goods-left {
            width: 50%;
            height: 87px;
            display: flex;

            .goods-img {
                width: 94px;
                height: 87px;
                margin-right: 27px;

                img{
                    width: 94px;
                    height: 87px; 
                }
            }

            .goods-world {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                span {
                    display: block;
                    height: 26px;
                    line-height: 26px;
                }
            }
        }

        .goods-right {
            width: 50%;
            height: 87px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            span {
                display: block;
                height: 26px;
                line-height: 26px;
            }
        }
    }
}

.pagination{
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-around;
    margin-top: 41px;
}