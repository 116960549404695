.discount-mask{
    width: 100%;
    height: 106.25rem;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5) ;
    left: 0;
    right: 0;
    z-index: 100001;
}
.rules-content{
    position: fixed;
    height: 28.125rem;
    width: 20.8438rem;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    background: #FFFCF7;
    border-radius: .4375rem;
    z-index: 100002;

    .title{
        width: 100%;
        height: 1.25rem;
        font-size: 1.25rem;
        text-align: center;
        line-height: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .title-data{
        padding: 0 .625rem;
        line-height: 1.5rem;

        p{
            margin: 0;
            font-size: 1rem !important;
        }
        
    }
}
.rules-close{
    width: 100%;
    height: .8438rem;
    margin-top: .75rem;
    margin-bottom: .75rem;
    display: flex;
    flex-direction: row-reverse;
}
.rules-close span{
    display: block;
    width: .8438rem;
    height: .8438rem;
    margin-right: .8125rem;
}
.rules-close span img{
    width: .8438rem;
    height: .8438rem;
}
.rules-bg{
    width: 100%;
    height: 6.4375rem;
    display: flex;
    padding-left: 4.4375rem;
    margin-bottom: 2.25rem;
}