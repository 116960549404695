.subscribe-main {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: SourceHanSansSC-regular;
    color: rgba(16, 16, 16, 100);

    .main {
        // width: 900px;
        height: 753px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .header {
            padding-top: 58px;
            padding-bottom: 60px;

            .title {
                font-size: 36px;
                text-align: center;
                margin: 0;
                padding-bottom: 16px;
                color: rgba(16, 16, 16, 100);
            }

            .title-bar {
                font-size: 18px;
                text-align: center;
                margin: 0;
                color: rgba(16, 16, 16, 100);
            }
        }

        .subscribe {
            width: 900px;
            height: 534px;
            margin: 0 auto;
            display: flex;

            .left {
                width: 356px;
                height: 534px;
                border: 1px solid rgba(187, 187, 187, 100);
                margin-right: 188px;
                display: flex;
                flex-direction: column;

                .left-title {
                    font-size: 18px;
                    text-align: center;
                    margin-top: 25px;
                    margin-bottom: 53px;
                }

                .left-main {
                    margin-bottom: 156px;

                    p {
                        color: rgba(16, 16, 16, 100);
                        font-size: 14px;
                        text-align: center;
                        margin: 0;
                    }
                }

                .left-price {
                    text-align: center;
                    font-size: 28px;

                    span {
                        font-size: 14px;

                    }
                }
            }

            .right {
                width: 356px;
                height: 534px;
                border: 1px solid rgba(187, 187, 187, 100);
                display: flex;
                flex-direction: column;

                .right-title {
                    font-size: 18px;
                    text-align: center;
                    margin-top: 25px;
                    margin-bottom: 53px;
                }

                .right-main {
                    margin-bottom: 156px;

                    p {
                        color: rgba(16, 16, 16, 100);
                        font-size: 14px;
                        text-align: center;
                        margin: 0;
                    }
                }

                .right-price {
                    text-align: center;
                    font-size: 28px;

                    s {
                        font-size: 20px;
                        text-align: center;
                    }

                    span {
                        font-size: 14px;

                    }

                    p {
                        font-size: 14px;
                        text-align: center;
                    }
                }

                .right-btn {
                    width: 185px;
                    height: 40px;
                    border-radius: 4px;
                    background: #D0B5AA;
                    color: #FFF;
                    font-size: 14px;
                    text-align: center;
                    font-family: Roboto;
                    margin: 0 auto;
                    margin-top: 12px;
                    line-height: 40px;
                    cursor: pointer;
                    border-radius: 20px;
                }
            }
        }

        .SMS_top{
            display: flex;

            h1{
                margin-right: 50px;
            }

            span{
                color: #C86032;
            }
        }

        .SMS_package{
            width: 100%;
            height: 100%;
            border: 1px solid rgba(187, 187, 187, 100);
            margin: 60px 0;
            padding: 20px;

            .ant-btn-primary{
                border-color:#C86032;
                background:#C86032;
            }
        }

        .SMS_bottom{
            width: 100%;
            height: 100%;
            padding-top: 10px;
        }
    }
}

.left-btn {
    width: 185px;
    height: 40px;
    border-radius: 4px;
    background: #D0B5AA;
    color: #FFF;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    margin: 0 auto;
    margin-top: 48px;
    line-height: 40px;
    cursor: pointer;
    border-radius: 20px;
}

.choose {
    width: 185px;
    height: 40px;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    margin: 0 auto;
    margin-top: 12px;
    line-height: 40px;
    cursor: pointer;
    color: #FFF;
    background: #C86032;
    border-radius: 20px;
}

.left-choose{
    width: 185px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    margin: 0 auto;
    margin-top: 48px;
    line-height: 40px;
    cursor: pointer;
    color: #FFF;
    background: #C86032;
    border-radius: 20px;
}