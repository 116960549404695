.shareing-main{
    margin-top: 2.375rem;
    margin-bottom: 6.3125rem;
    width: 100%;
    height: 38.75rem;

    .share-text{
        width: 100%;
        height: 7.25rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5625rem;
        text-align: center;
        margin-bottom: 2.6563rem;
    }
    .share-text p{
        color: #fff;
        font-size: 1rem;
        margin-bottom: 0;
    }
    .share-textarea{
        width: 100%;
        height: 10.1875rem;
        padding: 0 2.9375rem;
        margin-bottom: 2.1875rem;
    }
    .share-textarea textarea{
        width: 100%;
        height: 100%;
        border: 0;
        background: #FFFCF7;
        border-radius: .0938rem;
        outline: none;
        padding: 1.1875rem 1.375rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.3125rem;
    }
    .share-bottom{
        width: 100%;
        height: 3.5rem;
        padding: 0 4rem;
        margin-bottom: 3.25rem;
    }
    .share-btn{
        width: 100%;
        height: 3.4375rem;
        background: #FFEB69;
        border-radius: 1.625rem;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 3.4375rem;
        text-align: center;

        a{
            width: 100%;
            display: block;
            height: 3.4375rem;
            color: #000;
        }
    }
    .share-link{
        width: 100%;
        height: 6.375rem;
        display: flex;
        padding-left: 2.1875rem;
        flex-wrap: wrap;
        justify-content: center;
    }
    .share-link p{
        margin-right: 2.1875rem;
        margin-bottom: 2.1875rem;
        width: 2.1875rem;
    }
    
    .share-img{
        width: 2.1875rem !important;
        height: 2.1875rem !important;
    }
}
