.discount-mask{
    width: 100%;
    height: 106.25rem;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5) ;
    left: 0;
    right: 0;
    z-index: 100001;
}
.discount-content{
    position: fixed;
    height: 24.3125rem;
    width: 20.8438rem;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    background: #FFFCF7;
    border-radius: .4375rem;
    z-index: 100002;
}
.discount-close{
    width: 100%;
    height: .8438rem;
    margin-top: .75rem;
    margin-bottom: 1.375rem;
    display: flex;
    flex-direction: row-reverse;
}
.discount-close span{
    display: block;
    width: .8438rem;
    height: .8438rem;
    margin-right: .8125rem;
}
.discount-close span img{
    width: .8438rem;
    height: .8438rem;
}
.discount-bg{
    width: 100%;
    height: 6.4375rem;
    display: flex;
    padding-left: 4.4375rem;
    margin-bottom: 2.25rem;
}
.discount-bg img{
    width: 12rem;
    height: 6.4375rem;
}
.discount-text{
    width: 100%;
    height: 7.0313rem;
    display: flex;
    padding: 0 2.3125rem;
    flex-direction: column;
    margin-bottom: 1.7813rem;
}
.discount-text p{
    width: 15.9375rem;
    height: 2.8125rem;
    font-size: .9063rem;
    font-weight: 500;
    line-height: 1.4063rem;
}
.discount-text p:nth-child(2){
    height: 4.2188rem;
}
.discount-bottom{
    height: 1.1875rem;
    width: 100%;
    display: flex;
    padding: 0 5.75rem;
}
.discount-bottom p{
    height: 1.1875rem;
    display: flex;
}
.discount-bottom p .shop-icon{
    width: 1.375rem;
    height: 1.1875rem;
    margin-top: 0;
    margin-right: .5625rem;
}
.discont-shopName{
    display: block;
    width: 12.5rem;
    height: 1.1875rem;
    font-size: .9063rem;
    font-weight: 400;
    line-height: 1.1875rem;
}
.discount-arrow{
    width: .3438rem;
    height: .5313rem;
    display: block;
    margin: .375rem 0 .25rem .8125rem;
}
.discount-arrow img{
    width: .3438rem;
    height: .5313rem;
}
