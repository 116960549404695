.ranking-main{
  min-height: 20.125rem;
  width: 100%;

  .ranking-head{
    height: 1.3125rem;
    width: 100%;
    text-align: center;
    font-size: 1.375rem;
    font-family: Arial Rounded MT Bold;
    font-weight: 400;
    font-style: italic;
    color: #FFFFFF;
    line-height: 1.3125rem;
    margin-bottom: 1.375rem;
  }
  .ranking-rank{
    width: 100%;
    padding: 0 1.0625rem;
  }
  .raining-rank-main{
    width: 100%;
    min-height: 14.3125rem;
    background: #FFFCF7;
    border-radius: .2188rem;
    padding: 1.3125rem 1.0938rem 0 1.0938rem;
    /* overflow-y: scroll; */
  }
  .ranking-user{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 2.5938rem;
    margin-bottom: 1.3125rem;
  }
  .ranking-name{
    display: flex;
  }
  .ranking-name span:nth-child(1){
    display: block;
    width: 2.5938rem;
    height: 2.5938rem;
    background: #F4ECEA;
    border-radius: 50%;
    margin-right: 1.5rem;
  }
  .ranking-name span:nth-child(2){
    display: block;
    width: 2.5rem;
    height: .8438rem;
    font-size: 1.125rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 2.5938rem;
  }
  .ranking-price{
    display: flex;
  }
  .ranking-price span:nth-child(2){
    display: block;
    height: 2.5938rem;
    width: 2.7188rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 2.5938rem;
    margin-left: 1rem;
  }
  .ranking-price span:nth-child(1){
    display: block;
    height: .9688rem;
    width: .625rem;
    margin: .75rem 0 .875rem 0;
  }
  .rankingImg{
    height: .9688rem;
    width: .6563rem;
  }
  .ranking-footer{
    padding-top: 2.0625rem;
    width: 100%;
    text-align: center;
    height: .625rem;
    font-size: .625rem;
    font-weight: 500;
    color: #DDAEA8;
    line-height: 1.625rem;
    position: relative;
    bottom: 1.875rem;
    // bottom:-5rem;
  }

  .share-footer {
    width: 100%;
    height: 2.875rem;
    margin-top: 3.75rem;

    .footer-top {
      width: 100%;
      height: .625rem;
      font-size: .625rem;
      font-weight: 500;
      color: #CE2D4E;
      line-height: .625rem;
      text-align: center;
      margin-bottom: .75rem;
    }

    .footer-footer {
      width: 100%;
      height: .625rem;
      font-size: .625rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .75rem;
      text-align: center;
    }
  }
}
