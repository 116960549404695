*{
  margin: 0;
  padding: 0;
}
#root,body,html {
  height: 100%;
}
img{
  vertical-align:top;
  border:none
}
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote,
dl, dt, dd, ul, ol, li,pre,fieldset, lengend,button, input, textarea,th, td {
    margin: 0;
    padding: 0;
    font-family: PingFangSC-Regular;
    color: #635652;
    font-size: 14px;
    -webkit-user-select: auto;
    // 禁止鼠标拖动时复制
    -moz-user-select: none;
    // -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}
ul,li{
  list-style: none;
}

.point{
  cursor: pointer;
}
