.Share {
  p {
    margin: 0;
  }

  .Share-header {
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0px;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-bottom: .75rem;
    z-index: 1000;

    i {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 1.0625rem;
      font-size: 16px;
    }

    p {
      text-align: center;
      width: 100%;
      font-size: 1.125rem;
      margin-bottom: 0;
    }
  }

  .Share-main {
    position: relative;
    margin-top: 27px;
    width: 100%;
    min-height: 106.25rem;
    background: linear-gradient(-34deg, #FE6854, #FE5779, #FF7C49);;

    .main-top {
      width: 100%;
      height: 1.5rem;
      display: flex;
      margin-bottom: 2.6875rem;

      .main-top-left {
        height: 100%;
        width: 50%;
        margin-left: .5rem;
        display: flex;

          .shop-icon-img {
            width: .8125rem;
            height: .6875rem;
            display: block;
            margin-right: .4375rem;
            margin-top: .75rem;
          }

        .shop-name {
          width: 100%;
          display: block;
          font-size: .875rem;
          color: #fff;
          line-height: 1.0625rem;
          font-weight: 500;
          line-height: 2.125rem;
        }
      }

      .main-top-right {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: row-reverse;

        .right-icon {
          display: block;
          margin: .3125rem .4375rem 0 1.125rem;
          width: 1rem;
          height: 1rem;

          .user-icon-img {
            width: 1rem;
            height: 1rem;
          }
        }

        .user-name {
          display: block;
          font-size: .8125rem;
          font-weight: 500;
          color: #FFF;
          line-height: 2.125rem;
        }
      }
    }

    .main-body {
      width: 100%;
      min-height: 29.0625rem;

      .ninja-img {
        margin: 0 auto;
        background: url(../../assets/images/priceninja_bg.png) no-repeat;
        background-position: center center;
        background-size: 23.4375rem 3.8125rem;

        .ninja-div {
          margin: 0 auto;
          width: 15.875rem;
          height: 10.4375rem;

          .ninja-img-img {
            width: 15.875rem;
            height: 10.4375rem;
          }

          .cut-hand {
            width: 3.3125rem;
            height: 2.3125rem;
            position: relative;
            left: 0;
            top: -0.875rem;
          }

        }
      }




    }
  }
}