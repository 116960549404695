.cut-topbar {
    height: 40px;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;

    .cut-clickbar {
        width: 72px;
        height: 100%;
        font-size: 18px;
        margin: 0 80px 0px 0;
    }

    .create-cut {
        color: rgba(255, 255, 255, 100);
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        width: 185px;
        height: 40px;
        background: #C86032;
        border-radius: 20px;
        cursor: pointer;
    }
}

.search-input {
    width: 408px;
    height: 40px;
    border-radius: 20px;
    position: relative;

    img {
        position: absolute;
        width: 27px;
        height: 27px;
        left: 8px;
        margin-top: 6px;
        margin-left: 6px;
        z-index: 999;
    }

    Input {
        width: 408px;
        height: 40px;
        background: #F9F6F5;
        border-radius: 20px;
        text-indent: 40px;
        border: none;
    }

    .input-main:hover {
        border: none;
    }
}

.cut-tablebar {
    height: 30px;
    width: 100%;
    display: flex;
    margin: 26px 0 18px 0;

    .point {
        width: 120px;
        height: 30px;
        background: #C86032;
        border-radius: 15px;
        text-align: center;
        display: block;
        margin-right: 53px;
        font-size: 18px;
        color: #FFF;
    }

    .nopoint {
        width: 120px;
        height: 30px;
        color: #635652;
        border-radius: 15px;
        text-align: center;
        display: block;
        margin-right: 53px;
        font-size: 18px; 
    }

    .nopoint:hover{
        color: #C86032;
        cursor: pointer;
    }
}

.shopping {
    width: 100%;
    // height: 650px;
    height: 100%;
    overflow-y: scroll;

    .cut-shopping {
        width: 100%;
        height: 194px;
        margin-bottom: 29px;
        margin-top: 29px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .cut-img {
            width: 195px;
            height: 194px;
            background-color: #F9F6F5;

            img {
                width: 195px;
                height: 194px;
            }

            p:nth-child(1) {
                display: inline-block;
                width: 94px;
                height: 87px;
                background-color: green;
                margin: 0;
                margin-right: 7px;
            }

            p:nth-child(2) {
                display: inline-block;
                width: 94px;
                height: 87px;
                background-color: green;
                margin: 0;
            }

            p:nth-child(3) {
                display: inline-block;
                width: 94px;
                height: 87px;
                background-color: green;
                margin: 0;
                margin-right: 7px;
            }

            p:nth-child(4) {
                display: inline-block;
                width: 94px;
                height: 87px;
                background-color: green;
                margin: 0;
            }
        }

        .cut-name {
            height: 194px;
            width: 334px;
            margin-left: 31px;

            p {
                width: 334px;
                height: 26px;
                font-size: 16px;
                text-align: left;
            }
        }

        .cut-set {
            height: 194px;
            width: 529px;


            p {
                width: 529px;
                font-size: 16px;
                text-align: left;
            }
        }

        .cut-state {
            display: flex;
            width: 200px;
            justify-content: space-between;

            p {
                text-align: left;
                width: 156px;
                height: 34px;
                border-radius: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #635652;
                line-height: 34px;
                text-align: center;
            }

            .cut-icon {
                width: 26px;
                height: 26px;
            }
        }
    }

    .border-padding{
        height: 7px;
        width: 100%;
        // border-top: 1px solid #EBDED9;
        // border-bottom: 1px solid #EBDED9;
        background-color: #F6F6F7;
    }
}