.theme {
  color: #635652;
  height: 2175px;

  .theme-top {
    width: 72px;
    font-size: 18px;
    margin: 0 80px 26px 0;
  }

  .theme-main {
    display: flex;
    justify-content: space-between;

    .theme-left {
      width: 500px;
      height: 2175px;

      p {
        line-height: 26px;
        font-size: 18px;
      }

      .textmessage{
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 16px;
      }

      .theme-input-mian {
        margin-bottom: 52px;
      }

      .theme-input {
        width: 500px;
        height: 150px;
        margin-bottom: 12px;
        padding: 10px;
      }

      .copy-btn {
        width: 100%;
        display: flex;
        justify-content: space-around;

        .btn {
          width: 180px;
          height: 40px;
          border-radius: 4px;
          font-size: 18px;
          text-align: center;
          font-family: Roboto;
          margin: 0 auto;
          margin-top: 12px;
          line-height: 40px;
          cursor: pointer;
          color: #FFF;
          background: #C86032;
          border-radius: 20px;
        }
      }

      .theme-change {
        width: 100%;
        text-align: center;
        font-size: 16px;
        margin-top: 16px;
      }

    }

    .theme-middle {
      border: 1px solid #ebded9;
    }

    .theme-right {
      width: 684px;

      .theme-right-tabbar {
        width: 100%;
        height: 30px;
        text-align: center;

        .topbar{
          width: 360px;
          height: 40px;
          border-radius: 20px;
          background-color: #F3EEEC;
          margin: 0 auto;
        }

        .click {
          width: 180px;
          height: 40px;
          font-size: 18px;
          text-align: center;
          font-family: Roboto;
          margin: 0 auto;
          cursor: pointer;
          color: #FFF;
          background: #C86032;
          border-radius: 20px;
        }

        .noclick {
          color: #000;
        }

        span {
          display: inline-block;
          width: 180px;
          height: 40px;
          font-size: 18px;
          line-height: 40px;
          cursor: pointer;
        }

        .theme-img-computer {
          width: 684px;
          height: 390px;
          background-color: #C2B4AE;
          margin: 16px 0 96px 0;
          border: 1px solid rgba(187, 187, 187, 100);

          img{
            width: 682px;
            height: 388px;
          }
        }

        .theme-img-phone {
          width: 684px;
          height: 390px;
          margin: 16px 0 96px 0;
          display: flex;
          justify-content: space-around;

          .tt{
            width: 230px;
            height: 390px;
            border: 1px solid rgba(187, 187, 187, 100);
          }

          img{
            width: 228px;
            height: 388px;
          }
        }

        .theme-title {
          height: 26px;
          font-size: 18px;
          text-align: center;
          margin-bottom: 26px;
        }

        .theme-active{
          width: 326px;
          // height: 630px;
          background-color: #C2B4AE;
          margin: 0 184px;

          img{
            width: 326px;
            height: 1500px;
          }
        }
      }
    }
  }
}