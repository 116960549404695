.product-main {
  margin-top: 128px;
  width: 100%;
  height:27.4375rem;

  .product-header {
    width: 100%;
    height: 1rem;
    text-align: center;
    font-size: 1.375rem;
    font-family: Arial Rounded MT Bold;
    font-weight: 400;
    font-style: italic;
    color: #FFFFFF;
    line-height: 1rem;
    margin-bottom: 1.875rem;
  }

  .product-product {
    height: 21.25rem;
    width: 100%;
    padding: 0 1.125rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 1.5rem;
  }

  .product-main-img {
    width: 10.375rem;
    height: 10.375rem;
    background-color: #fff;
    border-radius: .3125rem;
  }

  img {
    width: 10.375rem;
    height: 10.375rem;
    border-radius: .3125rem;
  }

  .productImg {
    width: 100%;
    height: 100%;
    border-radius: .3125rem;
  }

  .product-btn-main {
    width: 100%;
    height: 1.8125rem;
    padding: 0 8rem;
  }

  .product-btn-btn {
    width: 100%;
    height: 1.8125rem;
    background: #D44B3D;
    border-radius: .9375rem;
    display: flex;

    img{
      display: block;
      width: .8125rem;
      height: .6875rem;
      margin: .5313rem .4688rem .6563rem .7813rem;
      border-radius: 0;
    }

    span{
      display: block;
      width: 100%;
      height: .5rem;
      font-size: .9375rem;
      font-family: Arial Rounded MT Bold;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.8125rem;
    }
  }

  .share-footer {
    width: 100%;
    height: 2.875rem;
    margin-top: 8.75rem;

    .footer-top {
      width: 100%;
      height: .625rem;
      font-size: .625rem;
      font-weight: 500;
      color: #CE2D4E;
      line-height: .625rem;
      text-align: center;
      margin-bottom: .75rem;
    }

    .footer-footer {
      width: 100%;
      height: .625rem;
      font-size: .625rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .75rem;
      text-align: center;
    }
  }
}