#dataIndex-top{
    width: 100%;
    height: 400px;
}

#dataIndex-bottom{
    width: 100%;
    height: 400px;
}

.select-time{
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row-reverse;
}

.select-times{
    z-index: 100004 !important;
}