.ant-layout {
  display: flex;
  width: 100%;
  min-height: 100% !important;
}

.site-sider-background {
  background: #F3EEEC !important;
}

.menu-background {
  background: #F3EEEC !important;
}

.site-layout-background {
  background: #FFF !important;
}

.ant-layout-sider-children {
  border-right: none;
  width: 170px;
  background-color: #F3EEEC;
  max-height: 2175px !important;
}

.ant-layout-header {
  display: flex;
  height: 100px !important;
  width: 100%;
  background-color: #C86032 !important;
  padding: 0 !important;
  justify-content: space-between;
  border: 0 solid #BBB;

  .header-left {
    display: flex;

    .logo {
      width: 117.5px;
      height: 87px;
      margin: 14px 21px 0px 23px;
      cursor: pointer;

      img {
        width: 117.5px;
        height: 87px;
      }
    }

    .website-name {
      width: 56px;
      height: 100px;
      line-height: 100px;
      text-align: left;
    }
  }

  .header-notice{
    color: yellow;
    line-height: 100px;

    a{
      color: rgb(49, 7, 233);
    }
  }

  .header-right {
    display: flex;

    .head-portrait {
      margin: 30px 10px 30px 0;
      border-radius: 40px;
      text-align: center;
      border: 1px solid rgba(187, 187, 187, 100);
      width: 50px;
      height: 50px;
      background-color: #E4CCC6;

      img {
        margin-top: 13px;
        margin-bottom: 14px;
        width: 26px;
        height: 23px;
      }
    }

    .username {
      margin-right: 26px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      color: #FFF;
    }

    .head-select {
      margin-right: 40px;
      line-height: 100px;
      color: #FFF;
    }

    .ant-dropdown-trigger>.anticon.anticon-down,
    .ant-dropdown-link>.anticon.anticon-down,
    .ant-dropdown-button>.anticon.anticon-down {
      font-size: 16px !important;
      line-height: 100px !important;
    }

  }

}

.ant-menu-item-selected {
  color: #C86032 !important;
  background: #F3EEEC !important;
}

.ant-menu-item-active {
  color: #C86032 !important;
}

.ant-menu-inline .ant-menu-item-selected:after {
  border-right: 0px !important;
}

.ant-menu-item {
  height: 60px !important;
}

.menu-style {
  position: absolute;
  top: -25px;
  left: 20px;

  .ant-dropdown-menu-item{
    text-align: center;
  }
}

.terms {
  width: 170px;
  height: 44px;
  position: fixed;
  bottom: 0;

  p {
    width: 150px;
    height: 1px;
    background-color: #C2B4AE;
    margin: 0 auto;
  }

  .terms-text {
    width: 100%;
    height: 42px;
    font-size: 12px;
    font-weight: 400;
    color: #635652;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
    background-color: #F3EEEC;
  }
}

.ant-menu-item-selected {
  background: #FFFFFF !important;
  border-radius: 30px !important;
}

.ant-menu-item::after {
  border-right: 0 !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: #F3EEEC !important;
}

.ant-menu-title-content {
  font-size: 16px !important;
}

.help{
  font-size: 16px;
  font-family: PingFangSC-Regular;
}

.ant-layout-content{
  height: 300px;
  overflow-y: scroll;
}